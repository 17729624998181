<template>
<div>
    <div class="card card-warning card-outline">
        <div class="card-header">
            <div class="card-title">
                <h4><i class="fa fa-briefcase"></i> Bestellungen</h4>
            </div>

            <div class="card-tools">
                <b-button size="sm" variant="primary" @click="createModal"><i class="fas fa-plus"></i></b-button>
            </div>
        </div>

        <div class="card-body">
            <template v-if="label.orders.length >= 1">
                <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <th>Datum</th>
                        <th>
                            Anzahl
                        </th>
                        <th>
                            Preis
                        </th>
                        <th>&Oslash;-Preis (Stück)</th>
                        <th style="width: 140px;">Aktionen</th>                 
                    </thead>
                    <tbody>
                        <tr v-for="(order) in label.orders" :key="order.id">
                            <td>{{ order.date }}</td>
                            <td>{{ order.quantity }}</td>
                            <td>{{ order.price | toCurrency }}</td>
                            <td>{{ order.price / order.quantity | toCurrency }}</td>
                            <td>
                                <b-button size="xs" @click="removeOrder(order.id)" variant="danger"><i class="fas fa-fw fa-trash"></i></b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </template>

            <template v-else>
                <h5>Keine Labels gefunden</h5>
            </template>
        </div>
    </div>

    <b-modal id="orderModal" title="Etikettenbestellung hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
        <div class="row">
            <div class="col-md-12">

                <div class="form-group">
                    <label for="date" class="control-label">Datum*</label>
                    <input v-model="orderForm.date" type="date" class="form-control form-control-sm" :class="{'is-invalid': orderForm.errors.has('date')}">
                    <has-error :form="orderForm" field="date"></has-error>
                </div>

                <div class="form-group">
                    <label for="quantity" class="control-label">Anzahl*</label>
                    <input v-model="orderForm.quantity" type="number" class="form-control form-control-sm" :class="{'is-invalid': orderForm.errors.has('quantity')}">
                    <has-error :form="orderForm" field="quantity"></has-error>
                </div>

                <div class="form-group">
                    <label for="price" class="control-label">Preis (EK/Netto)*</label>
                    <input v-model="orderForm.price" type="number" class="form-control form-control-sm" :class="{'is-invalid': orderForm.errors.has('price')}">
                    <has-error :form="orderForm" field="price"></has-error>
                </div>
            </div>
        </div>
    </b-modal>

</div>
</template>

<script>
export default {
    name: "Orders",

    props:[
        'label'
    ],

    data(){
        return {
            orderForm: new window.Form({
                id: "",
                label_id: "",
                date: "",
                quantity: "",
                price:"",
            }),
        }
    },

    methods:{
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.addOrder();     
        },

        createModal() {
            this.orderForm.clear();
            this.orderForm.reset();
            this.$bvModal.show("orderModal");
        },

        addOrder(){
          this.orderForm.label_id = this.label.id;
          this.orderForm
                .post("/labelorders")
                .then(() => {
                    this.$parent.loadLabel();
                    this.$bvModal.hide("orderModal");
                    this.$swal({
                        icon: "success",
                        title: "Bestellung gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });

        },

        removeOrder(id){
          this.$swal({
                title: "Möchtest du die Bestellung wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/labelorders/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Bestellung wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$parent.loadLabel();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },
    }
}
</script>

<style>

</style>